import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import FileManager from "./FileManager";

export default ({
  folderId,
  docs,
  setDocs,
  breadcrumbs,
  page,
  maxPages,
  loading,
  onDoubleClick,
  onPushDocumentInUploadList,
  filePropertiesDialog,
  handleFilePropertiesDialog,
  documentViewerDialog,
  handleDocumentViewerDialog,
  newFolderDialog,
  handleNewFolderDialog,
  onDownload,
  onDelete,
  onAddNewFolder,
  onUpdateFileProperties,
  onMoveDocument,
  fetchGroups,
  fetchUsers,
  loadingGroup,
  groupOptions,
  loadingUser,
  userOptions,
  permission,
}) => {
  const [contextMenu, setContextMenu] = useState({ show: false });

  const clearDocListClassnames = useCallback(() => {
    document.querySelectorAll(`li[file-id]`).forEach(el => {
      el.className = "";
    });
  }, []);

  const clearDocListMeta = useCallback(() => {
    setDocs(
      docs.map(doc => {
        return {
          ...doc,
          selected: false,
          currentSelected: false,
        };
      }),
    );
  }, [docs, setDocs]);

  const handleDelete = useCallback(() => {
    const selectedDocs = docs.filter(doc => doc.selected);

    setContextMenu({ show: false });

    if (
      window.confirm(
        "Deseja enviar o(s) documento(s) selecionado(s) para a lixeira?",
      )
    ) {
      onDelete(selectedDocs);
    }
  }, [docs, onDelete]);

  const handlePressKey = useCallback(
    event => {
      if (event.key === "Escape") {
        clearDocListMeta();
        setContextMenu({ show: false });
      } else if (event.key === "Enter") {
        const currentSelectDoc = docs.find(doc => doc.currentSelected);

        onDoubleClick(currentSelectDoc);
      } else if (event.key === "Delete") {
        handleDelete();
      }
    },
    [clearDocListMeta, docs, onDoubleClick, handleDelete],
  );

  const handleDragStart = event => {
    const id = parseInt(event.currentTarget.getAttribute("file-id"));

    if (!docs.find(doc => doc.selected)) {
      setDocs(
        docs.map(doc => {
          if (doc.id === id) return { ...doc, selected: true };
          return doc;
        }),
      );

      setTimeout(function() {
        document
          .querySelectorAll(`li[file-id="${id}"]`)[0]
          .classList.add("block-hide");
      });
    }

    docs.forEach(doc => {
      if (doc.selected) {
        setTimeout(function() {
          document
            .querySelectorAll(`li[file-id="${doc.id}"]`)[0]
            .classList.add("block-hide");
        });
      }
    });
  };

  const handleDragEnd = () => {
    clearDocListClassnames();
  };

  const handleDragOver = event => {
    event.preventDefault();

    const id = parseInt(event.currentTarget.getAttribute("file-id"));
    if (
      docs.find(
        doc => doc.selected === false && doc.id === id && doc.type === "dir",
      )
    ) {
      event.currentTarget.classList.add("overdir");
    }
  };

  const handleCurrentFolderDragOver = event => {
    event.preventDefault();

    event.currentTarget.parentNode.classList.add("overdir");
  };

  const handleCurrentFolderDragLeave = () => {
    const elements = document.getElementsByClassName("overdir");
    [...elements].forEach(element => {
      element.classList.remove("overdir");
    });
  };

  const handleCurrentFolderDrop = event => {
    event.preventDefault();

    if (event.dataTransfer.items.length) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const item = event.dataTransfer.items[i].webkitGetAsEntry();

        if (item) {
          handleScanDocTree(item, "", folderId);
        }
      }
    }

    clearDocListMeta();
    event.currentTarget.parentNode.classList.remove("overdir");
  };

  const handleDragLeave = event => {
    event.currentTarget.classList.remove("overdir");
  };

  const handleClick = event => {
    const id = parseInt(event.currentTarget.getAttribute("file-id"));
    const currentSelectDoc = docs.filter(doc => doc.currentSelected);

    if (!event.ctrlKey) {
      if (event.shiftKey && currentSelectDoc.length) return;

      const newDocs = docs.map(doc => {
        if (doc.id === id) {
          return { ...doc, selected: true, currentSelected: true };
        } else {
          return { ...doc, selected: false, currentSelected: false };
        }
      });

      setDocs(newDocs);
      setContextMenu({ show: false });
    }
  };

  const handleMouseDown = event => {
    const id = parseInt(event.currentTarget.getAttribute("file-id"));

    if (event.ctrlKey || event.shiftKey) {
      if (event.ctrlKey) {
        if (!docs.filter(doc => doc.selected).find(doc => doc.id === id)) {
          setDocs(
            docs.map(doc => {
              if (doc.id === id) {
                return { ...doc, selected: true };
              }

              return { ...doc };
            }),
          );
        } else {
          setDocs(
            docs.map(doc => {
              if (doc.id === id) {
                return { ...doc, selected: false };
              }

              return { ...doc };
            }),
          );
        }
      } else if (event.shiftKey) {
        let locked = true;
        const currentSelectedDoc = docs.find(doc => doc.currentSelected);

        if (currentSelectedDoc) {
          const newDocs = docs.map(doc => {
            if (doc.id === id || doc.id === currentSelectedDoc.id) {
              if (!locked) {
                locked = !locked;

                return { ...doc, selected: true };
              }

              locked = !locked;
            }

            return !locked
              ? { ...doc, selected: true }
              : { ...doc, selected: false };
          });

          setDocs(newDocs);
        }
      }
    }
  };

  const handleContextMenu = event => {
    event.preventDefault();

    const id = parseInt(event.currentTarget.getAttribute("file-id"));
    const selectedDocs = docs.filter(doc => doc.selected);

    if (selectedDocs.length <= 1 || !selectedDocs.find(doc => doc.id === id)) {
      setDocs(
        docs.map(doc => {
          if (doc.id === id) {
            return { ...doc, selected: true, currentSelected: true };
          }

          return { ...doc, selected: false, currentSelected: false };
        }),
      );
    }

    setContextMenu({ show: true, x: event.clientX, y: event.clientY });
  };

  const handleDrop = event => {
    event.preventDefault();

    const id = parseInt(event.currentTarget.getAttribute("file-id"));

    if (
      !docs
        .filter(doc => doc.selected)
        .find(doc => doc.id === id) /*&& Verificar se é um diretório válido */
    ) {
      if (event.dataTransfer.items.length) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          const item = event.dataTransfer.items[i].webkitGetAsEntry();

          if (item) {
            handleScanDocTree(item, "", id);
          }
        }
      } else {
        handleMoveDoc(id);
      }
    }

    clearDocListMeta();
  };

  const handleMoveDoc = targetFolderId => {
    const targetFolder = docs.find(doc => doc.id === targetFolderId);

    if (targetFolder.type === "dir") {
      onMoveDocument(
        docs.filter(doc => doc.selected).map(doc => doc.id),
        targetFolderId,
      );
    }
  };

  const handleScanDocTree = (item, path, parentFolderId) => {
    path = path || "";

    if (item.isFile) {
      item.file(file => {
        onPushDocumentInUploadList({
          id: uuidv4(),
          path: path + file.name,
          processing: false,
          finished: false,
          success: false,
          data: file,
          parentId: parentFolderId,
        });
      });
    } else if (item.isDirectory) {
      const dirReader = item.createReader();

      dirReader.readEntries(entries => {
        for (let i = 0; i < entries.length; i++) {
          handleScanDocTree(entries[i], path + item.name + "/", parentFolderId);
        }
      });
    }
  };

  const handleDoubleClick = () => {
    const currentSelectDoc = docs.find(doc => doc.currentSelected);

    onDoubleClick(currentSelectDoc);
  };

  const onLoadFilePropertiesDialog = options => {
    handleFilePropertiesDialog(
      docs.find(doc => doc.selected),
      options,
    );
    setContextMenu({ show: false });
  };

  const handleDownload = () => {
    const selectedDocs = docs.filter(doc => doc.selected);

    onDownload(selectedDocs);
    setContextMenu({ show: false });
  };

  useEffect(() => {
    function hideContextMenu(event) {
      setContextMenu({ show: false });

      if (!event.target.closest("#fm")) {
        clearDocListMeta();
      }
    }

    document.addEventListener("keydown", handlePressKey);
    document.addEventListener("click", hideContextMenu);

    return () => {
      document.removeEventListener("keydown", handlePressKey);
      document.removeEventListener("click", hideContextMenu);
    };
  }, [clearDocListMeta, handlePressKey]);

  useEffect(() => {
    clearDocListClassnames();

    if (docs) {
      const selectedDocs = docs.filter(doc => doc.selected);

      if (selectedDocs.length === 1) {
        document
          .querySelectorAll(`li[file-id="${selectedDocs[0].id}"]`)[0]
          .classList.add("fileclick");
      } else {
        selectedDocs.forEach(doc => {
          document
            .querySelectorAll(`li[file-id="${doc.id}"]`)[0]
            .classList.add("fileselected");
        });
      }
    }
  }, [docs, clearDocListClassnames]);

  return (
    <FileManager
      loading={loading}
      folderId={folderId}
      docs={docs}
      breadcrumbs={breadcrumbs}
      page={page}
      maxPages={maxPages}
      handleDragStart={handleDragStart}
      handleDragEnd={handleDragEnd}
      handleCurrentFolderDragOver={handleCurrentFolderDragOver}
      handleCurrentFolderDragLeave={handleCurrentFolderDragLeave}
      handleCurrentFolderDrop={handleCurrentFolderDrop}
      handleDragOver={handleDragOver}
      handleDragLeave={handleDragLeave}
      handleClick={handleClick}
      handleMouseDown={handleMouseDown}
      handleDrop={handleDrop}
      handleContextMenu={handleContextMenu}
      handleDoubleClick={handleDoubleClick}
      contextMenu={contextMenu}
      filePropertiesDialog={filePropertiesDialog}
      handleFilePropertiesDialog={onLoadFilePropertiesDialog}
      documentViewerDialog={documentViewerDialog}
      handleDocumentViewerDialog={handleDocumentViewerDialog}
      newFolderDialog={newFolderDialog}
      handleNewFolderDialog={handleNewFolderDialog}
      onDownload={handleDownload}
      onDelete={handleDelete}
      onAddNewFolder={onAddNewFolder}
      onUpdateFileProperties={onUpdateFileProperties}
      fetchGroups={fetchGroups}
      fetchUsers={fetchUsers}
      loadingGroup={loadingGroup}
      groupOptions={groupOptions}
      loadingUser={loadingUser}
      userOptions={userOptions}
      permission={permission}
    />
  );
};
