import React, { useState } from "react";
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useInfiniteQuery } from "react-query";
import { Backdrop } from "@mui/material";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";

import { getCompanies } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import Services from "../Services";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyCompany() {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const [companyQuery, setCompanyQuery] = useState("");
  const [titleCompanyDialog, setTitleCompanyDialog] = useState("");

  const companyCode = searchParams.get("company_code");

  const companiesQuery = useInfiniteQuery(
    ["companies", companyQuery],
    ({ pageParam = 1 }) => {
      return getCompanies(companyQuery, {
        page: pageParam,
        logo: true,
      });
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.data?.data?.length === 0) {
          return false;
        }

        return lastPage.data?.meta?.current_page + 1;
      },
      refetchOnWindowFocus: false,
    },
  );

  const companies =
    companiesQuery.data?.pages?.reduce((data, page) => {
      return [...data, ...page.data.data];
    }, []) ?? [];

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: companiesQuery?.hasNextPage ?? false,
    onLoadMore: companiesQuery?.fetchNextPage,
    disabled: !!companiesQuery.error,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleOpenCompany = (id, title) => {
    setTitleCompanyDialog(title);

    setSearchParams({ company_code: id });
  };

  const handleCloseCompany = () => {
    searchParams.delete("company_code");
    setSearchParams(searchParams);
  };

  const searchDelayed = debounce(query => {
    setCompanyQuery(query);
  }, 1000);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={companiesQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>

      <Dialog
        fullScreen
        open={!!companyCode}
        onClose={handleCloseCompany}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseCompany}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {titleCompanyDialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <Services />
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "25px",
        }}
      >
        <TextField
          style={{ width: "400px" }}
          label="Pesquisar"
          variant="outlined"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={calendar => {
            searchDelayed(calendar.target.value);
          }}
        />
      </Box>

      <Grid container spacing={2}>
        {companies.map(company => (
          <Grid key={company.APS_ID} item xs={12} sm={6} md={4} lg={3}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100px",
                alignItems: "center",
                padding: "15px",
                cursor: "pointer",
                gap: "10px",
              }}
              onClick={() =>
                handleOpenCompany(
                  `${company.APS_COD_EMPRESA}${company.APS_COD_FILIAL}`,
                  company.APS_DESC_FILIAL,
                )
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={`data:image/png;base64,${company.APS_LOGO_B64}`}
                  alt="logo da empresa"
                  style={{
                    maxHeight: "35px",
                  }}
                />
              </Box>
              <Typography variant="subtitle1">
                {`${company.APS_COD_EMPRESA.substring(0, 2)}${
                  company.APS_COD_FILIAL
                } - ${company.APS_DESC_FILIAL}`}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {companiesQuery?.data &&
        (companiesQuery?.isLoading || companiesQuery?.hasNextPage) && (
          <Box
            ref={sentryRef}
            sx={{ display: "flex", justifyContent: "center", padding: "25px" }}
          >
            <CircularProgress />
          </Box>
        )}
    </>
  );
}
